@use 'sass:math';
@import '../variables';

.body {
  color: var(--background-highlight-color);
  margin: 0 0 #{ $spacing-unit - 1 }rem;

  @media (prefers-color-scheme: light) {
    color: var(--main-color);
  }
}

h1.title {
  font-size: 2.618rem;
  line-height: 3.236rem;
}

h2.title {
  font-size: 1.618rem;
}

h3.title {
  font-size: 1rem;
}

.small {
  font-size: 0.618rem;
}
