@use 'sass:math';

@import '../variables';

.wrapper {
  align-items: flex-start;
  background: var(--background-soft-highlight-color);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  margin-bottom: #{$spacing-unit - 1}rem;
  padding: #{math.div($spacing-unit, 1.5)}rem;
  position: relative;
}

.label {
  margin-bottom: #{($spacing-unit - 1) * 0.5}rem;
}

.input {
  margin-bottom: #{$spacing-unit - 1}rem;
}

.input {
  background: none;
  border: none;
  border-bottom: 2px solid var(--main-color);
  color: var(--main-color);
  flex: 0 0 0;
  height: #{$spacing-unit}rem;
  padding: #{$spacing-unit * 0.5}rem 0;
  width: 100%;

  &::placeholder {
    color: var(--background-highlight-color);
  }
  @media screen and (min-width: 600px) {
    max-width: 18rem;
  }
}

.inputError {
  border-bottom: 2px solid var(--accent-secondary-color);
  color: var(--accent-secondary-color);
}

.submit {
  background: var(--accent-secondary-color);
  border: none;
  border-radius: $border-radius;
  color: $main-light-color;
  padding: #{math.div($spacing-unit, 3)}rem #{$spacing-unit*0.5}rem;

  &:hover {
    cursor: pointer;
    filter: brightness(116.18033%);
  }

  &:disabled {
    cursor: not-allowed;
    filter: brightness(83.81967%);

    &:hover {
      filter: none;
    }
  }
}

.feedback {
  font-size: #{$spacing-unit*0.5}rem;
  font-style: italic;
  margin-bottom: #{$spacing-unit - 1}rem;

  &Info {
    color: var(--background-highlight-color);
  }

  &Fail {
    color: var(--accent-secondary-color);
  }

  &Success {
    color: var(--success-color);
  }
}

.select {
  appearance: none;
  background: none;
  background-position: center right 0.5rem;
  background-repeat: no-repeat;
  border: none;
  border-bottom: 2px solid var(--main-color);
  color: var(--main-color);
  margin-bottom: #{$spacing-unit - 1}rem;
  max-width: 18rem;
  padding: #{$spacing-unit - 1}rem;
  padding-left: 0;
  width: 100%;
}

.button {
  appearance: none;
  background: var(--background-soft-highlight-color);
  border: 2px solid var(--main-color);
  border-radius: $border-radius;
  color: var(--main-color);
  margin-bottom: #{$spacing-unit - 1}rem;
  padding: #{$spacing-unit*0.25}rem #{math.div($spacing-unit, 3)}rem;

  &:hover {
    cursor: pointer;
    filter: brightness(116.18033%);
  }

  &:disabled {
    cursor: not-allowed;
    filter: opacity(38.1967%);
  }

  &--tiny {
    font-size: #{$spacing-unit*0.5}rem;
    padding: #{math.div($spacing-unit, 12)}rem #{math.div($spacing-unit, 9)}rem;
  }
}
