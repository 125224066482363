@import '../variables';

.inline-field {
  max-width: 18rem;
  position: relative;
  width: 100%;

  button {
    bottom: 0.25rem;
    display: block;
    position: absolute;
    right: 0;
  }

  svg {
    bottom: 0.75rem;
    height: 30px;
    position: absolute;
    right: 0;
    width: 30px;
  }

  .short {
    padding-right: 50%;
  }

  .inline-complement {
    bottom: 0.75rem;
    display: block;
    position: absolute;
    right: 0;
  }
}


.remove-icon {
  height: 10px;
  width: 10px;

  path {
    fill: white;
  }
}

.buttons {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 18rem;
  padding-top: #{$spacing-unit - 1}rem;
  width: 100%;
}
