@import '../variables';

.dashboard {
  align-items: center;
  color: var(--main-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 36rem;
  padding: #{ $spacing-unit }rem;
}

.dashboard-content {
  padding: 0 #{ $spacing-unit*0.5 }rem;
  position: relative;
  width: 100%;
}
