@import '../../components/variables';

.top {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: #{$spacing-unit - 1}rem 0 0;

  h2 {
    margin: 0;
    padding: 0;
  }
  @media screen and (min-width: 600px) {
    padding: #{$spacing-unit*0.125}rem 0 0;
  }
}

.start {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &Illustration {
    height: auto;
    padding: #{$spacing-unit}rem 0;
    width: 61.8033%;
  }
}
