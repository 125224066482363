@use 'sass:math';
@import '../variables';

.headerLogo {
  width: 7rem;
}

.headerLogo path {
  fill: var(--main-color);
}

.header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;

  &Nav {
    align-items: center;
    display: flex;
    position: relative;
  }

  &NavList {
    display: flex;
    list-style: none;
    margin: #{ $spacing-unit - 1 }rem 0;
    padding: 0;
  }

  &NavListItem {
    margin: 0 #{ $spacing-unit * 0.5 }rem;
    position: relative;

    a {
      text-decoration: none;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &Active {
      &::after {
        animation: active 300ms;
        background-color: var(--accent-secondary-color);
        border-radius: 9999px;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }

  &Link,
  a {
    font-size: #{ $spacing-unit * 0.5 }rem;
    font-weight: bold;
  }
}

@keyframes active {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
