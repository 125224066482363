@use 'sass:math';
@import '../variables';

.headerLogoWrapper,
.headerLogoWrapper:hover {
  display: flex;
  margin-bottom: -5px;
}

.headerLogo {
  width: 5rem;
}

.headerLogo path {
  fill: var(--main-color);
}

.header {
  align-items: center;
  background: var(--background-soft-highlight-color);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: #{ $spacing-unit*0.5 }rem;
  position: relative;
  width: 100%;
  @media screen and (min-width: 640px) {
    flex-direction: row;
  }

  &Nav {
    align-items: center;
    display: flex;
    position: relative;
  }

  &NavList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: #{$spacing-unit - 1}rem 0 0;
    @media screen and (min-width: 640px) {
      padding: 0;
    }
  }

  &NavListItem {
    margin: 0 #{ math.div($spacing-unit, 3) }rem;
    position: relative;
    @media screen and (min-width: 640px) {
      margin: 0 #{ $spacing-unit * 0.5 }rem;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      text-decoration: none;
    }

    &Active {
      &::after {
        animation: active 300ms;
        background-color: var(--accent-secondary-color);
        border-radius: 9999px;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }

  &Link,
  a {
    font-size: #{ $spacing-unit * 0.5 }rem;
    font-weight: bold;
  }
}


@keyframes active {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}


