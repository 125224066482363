// Colors
/* stylelint scss/dollar-variable-pattern: /[a-z][a-zA-Z]+/ */
$main-dark-color: #0f0e17;
$highlight-dark-color: #a7a9be;
$main-light-color: #fffffe;
$highlight-light-color: #2e2f3e;
$primary-color: #ff8906;
$secondary-color: #e53170;
$success-color: #cbe896;
$error-color: #af1b3f;

// Spacing
$spacing-unit: 1.618033;

// Radius
$border-radius: 10px;
