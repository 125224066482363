@use 'sass:math';

@import '../variables';

.button {
  background: var(--accent-secondary-color);
  border: 0;
  border-radius: $border-radius;
  color: $main-light-color;
  padding: #{math.div($spacing-unit, 3)}rem #{$spacing-unit*0.5}rem;

  &:hover {
    cursor: pointer;
    filter: brightness(116.18033%);
  }
}
